import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import SearchBar from "../../components/SearchBar";
import { useHTTPRequest } from "../../hooks/contexts/HTTPRequestContext";
import { useSpinner } from "../../hooks/contexts/SpinnerContext";
import { TransactionDto } from "../../model/transaction.dto";
import TransactionListEntry from "./TransactionListEntry";

// const fetchTransactions = async (prefix: string): Promise<TransactionDto[]> => {
//     return fetch(`${process.env.REACT_APP_SERVER_URL}/rest/transactions/${prefix}?prefix=true`)
//         .then(response => response.json() as unknown as Promise<TransactionDto[]>)
//         .catch(err => {
//             return err;
//         })
// }

const Transaction = () => {

    const [transactions, setTransactions] = useState<TransactionDto[]>([]);
    const snackbar = useSnackbar();
    const spinner = useSpinner();

    const httpRequest = useHTTPRequest();

    const getTransactions = useCallback(async (prefix: string) => {
        setTransactions([]);
        if (prefix.length < 4) {
            snackbar.enqueueSnackbar('Deve informar ao menos os 4 primeiros dígitos para a busca', { variant: 'warning' });
            return;
        }

        try {
            spinner.setLoading(true);
            const response = await httpRequest.get(`${process.env.REACT_APP_SERVER_URL}/transactions/${prefix}?prefix=true`)
                .then(r => r.json() as TransactionDto[])
            setTransactions(response);
            if (response.length > 0) {
                if (response.length === 1) {
                    snackbar.enqueueSnackbar(`Transação encontrada.`, { variant: 'info' });
                } else {
                    snackbar.enqueueSnackbar(`Um total de ${response.length} transações foram encontradas.`, { variant: 'info' });
                }
            } else {
                snackbar.enqueueSnackbar(`Nenhuma transação foi encontrada`, { variant: 'warning' });
            }

        } catch (err) {
            console.error(err);
            snackbar.enqueueSnackbar((err as Error).message || 'Algo deu errado!', { 'variant': 'error' });
        } finally {
            setTimeout(() => {
                spinner.setLoading(false);
            }, 500);
        }
    }, [httpRequest, snackbar, spinner]);

    return (<div className="flex flex-auto w-full h-full flex-col">
        <div className="flex">
            <SearchBar onSearch={(term) => getTransactions(term)} />
        </div>
        <div className="flex items-center p-10 flex-col">
            {transactions.length > 0 ?
                transactions.map(t => <TransactionListEntry transaction={t} />)
                :
                <div className="text-slate-400 font-medium">Nenhuma transação encontrada</div>
            }
        </div>
    </div>)
}


export default Transaction;